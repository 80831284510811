import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios"

function Note(props) {

  //////// Handle deletion immediately /////////
  // function handleClick() {
  //   client.delete(`${props.id}`)
  //   .then((res) => alert(res.data.message))
  // }

  //////// LET PARENT HANDLE DELETION  /////////
  function handleClick() {
    props.onDelete(props.id);
  }

  return (
    <div className="note">
      <h1>{props.title}</h1>
      <p>{props.content}</p>
      <button onClick={handleClick}><DeleteIcon /></button>
    </div>
  );
}

export default Note;
